<template>
  <div>
    <ColorPickerUI
      name="Color Picker #1"
      :color="color"
      @updateColor="updateColor"
    ></ColorPickerUI>
    <p class="mt-5">Selected Color : {{ color }}</p>
  </div>
</template>

<script>
import ColorPickerUI from "@/components/ui/ColorPickerUI";
export default {
  components: {
    ColorPickerUI
  },
  data() {
    return {
      color: { hex: "#00F0F0" }
    };
  },
  methods: {
    updateColor(clr) {
      console.log("XXXX updateColor =", clr);
      this.color = clr;
    }
  }
};
</script>

<style scoped>
/* Custom styling for the slider if necessary */
</style>
