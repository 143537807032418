<template>
  <div>
    <p class="my-5">Selected Name: {{ name }}</p>
    <Chrome v-model="localColor" />
    <p class="mt-5">Selected color: {{ localColor }}</p>
  </div>
</template>

<script>
import { Chrome } from "@ckpack/vue-color";

export default {
  components: {
    Chrome
  },
  data() {
    return {
      localColor: "#194D33A8"
      // etc: { h: 150, s: 0.66, v: 0.30 }, { r: 255, g: 0, b: 0 }, '#194d33'
    };
  },
  props: ["name", "color"],
  mounted() {
    this.localColor = this.color;
    console.log("color =", this.color);
    console.log("localColor =", this.localColor);
  },
  watch: {
    localColor(newValue) {
      console.log("localColor =", newValue);
      this.$emit("updateColor", newValue); // Emit event to update color in parent
    }
  }
};
</script>

<style scoped>
/* Custom styling for the slider if necessary */
</style>
